// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { StreamActiveIcon } from 'components/Icons/Icons';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import { DateTimeIcon } from 'components/Icons/Icons';

const StreamEditContent = ({ newStream, newStreamErrors, stream, setNewStream, setNewStreamErrors, handleDeleteStream, handleCreateStream }) => {
  const history = useHistory();
  const isValidInputName = useMemo(() => !newStreamErrors.includes('name'), [newStreamErrors]);
  const isValidInputDescription = useMemo(() => !newStreamErrors.includes('description'), [newStreamErrors]);
  const isValidInputURL = useMemo(() => !newStreamErrors.includes('url'), [newStreamErrors]);
  const isValidInputOrder = useMemo(() => !newStreamErrors.includes('order'), [newStreamErrors]);
  const isValidInputStartDate = useMemo(() => !newStreamErrors.includes('startDate'), [newStreamErrors]);

  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');

  const getSafeDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };

  const handleActiveChange = useCallback((e) => {
    setNewStream({...newStream, hidden: !newStream.hidden });
  }, [newStream, setNewStream]);

  const handleNewStreamNameChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length < 3) {
      setNewStreamErrors([...newStreamErrors, 'name']);
    } else {
      setNewStreamErrors(newStreamErrors.filter(error => error !== 'name'));
    }
    setNewStream({...newStream, name: value});
  }, [setNewStream, newStream, setNewStreamErrors, newStreamErrors]);

  const handleNewStreamDescriptionChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length < 3) {
      setNewStreamErrors([...newStreamErrors, 'description']);
    } else {
      setNewStreamErrors(newStreamErrors.filter(error => error !== 'description'));
    }
    setNewStream({...newStream, description: value});
  }, [newStream, newStreamErrors, setNewStream, setNewStreamErrors]);

  const handleNewStreamURLChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length < 3) {
      setNewStreamErrors([...newStreamErrors, 'url']);
    } else {
      setNewStreamErrors(newStreamErrors.filter(error => error !== 'url'));
    }
    setNewStream({...newStream, url: value});
  }, [setNewStream, newStream, setNewStreamErrors, newStreamErrors]);

  const handleNewStreamOrderChange = useCallback((e) => {
    const value = e.target.value;
    if (!value) {
      setNewStreamErrors([...newStreamErrors, 'order']);
    } else {
      setNewStreamErrors(newStreamErrors.filter(error => error !== 'order'));
    }
    setNewStream({...newStream, order: value});
  }, [setNewStream, newStream, setNewStreamErrors, newStreamErrors]);

  const handleNewStreamStartDateChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length < 3) {
      setNewStreamErrors([...newStreamErrors, 'startDate']);
    } else {
      setNewStreamErrors(newStreamErrors.filter(error => error !== 'startDate'));
    }
    setNewStream({...newStream, startDate: value});
  }, [newStream, newStreamErrors, setNewStream, setNewStreamErrors]);

  const handleCancelClick = useCallback(() => {
    setNewStream(null);
    history.push('/stream');
  }, [history, setNewStream]);

  return (
    <>
      {
        newStream.id ? (
          <Breadcrumbs icon={StreamActiveIcon} target='Stream' url='#/stream' current='Stream' variant="standart" />
        ) : (
          <Breadcrumbs icon={StreamActiveIcon} target='Stream' url='#/stream' current='New Stream' variant="standart"/>
        )
      }

      <Card padding="24px" mb="8px" maxW="839px">
        <Table>
          <Tbody>
            <Tr>
              <Td verticalAlign="top" w={{sm: '5%'}} border="unset" p="0 0 0 0">
                <Text variant="title">Active</Text>
              </Td>
              <Td verticalAlign="top" border="unset" p="0 0 0 0">
                <Switch 
                  float='right' 
                  id='email-alerts' 
                  isChecked={!newStream?.hidden} 
                  onChange={handleActiveChange} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>

      <Text variant="title-lg" mt="40px" mb="14px">Stream info</Text>
      <Card padding="24px" mb="8px" maxW="839px">
        <Grid templateColumns={{ sm: '1fr', lg: '3fr 1fr 0.6fr' }} templateRows='1fr' gap="16px" mb="-16px">
            <FormControl variant="floating" id="name">
              <Input value={newStream.name} type='text' placeholder='' autoComplete="off" mb='32px' onChange={handleNewStreamNameChange}  className={isValidInputName ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="name" className={isValidInputName ? 'input-valid' : 'input-invalid'}>Name</FormLabel>
              <FormHelperText className={isValidInputName ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating">
              <Input value={
                newStream?.startDate ? formatDate(newStream?.startDate) : null
              } type='datetime-local' mb='24px' onChange={handleNewStreamStartDateChange} className={isValidInputStartDate ? 'input-valid' : 'input-invalid'}/>
              <FormLabel className={isValidInputStartDate ? 'input-valid' : 'input-invalid'}>Start game</FormLabel>
              <FormHelperText className={isValidInputStartDate ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>

            <FormControl variant="floating" id="order">
                <Input value={newStream.order} type='number' placeholder='' autoComplete="off" onChange={handleNewStreamOrderChange}  className={isValidInputOrder ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="order" className={isValidInputOrder ? 'input-valid' : 'input-invalid'}>Order</FormLabel>
                <FormHelperText className={isValidInputOrder ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
            </FormControl>
        </Grid>
        <Box>
          <FormControl variant="floating" id="url">
              <Input value={newStream.url} type='text' placeholder='' autoComplete="off" onChange={handleNewStreamURLChange}  className={isValidInputURL ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="url" className={isValidInputURL ? 'input-valid' : 'input-invalid'}>Stream URL</FormLabel>
              <FormHelperText className={isValidInputURL ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
          </FormControl>

          <FormControl variant="floating" id="description" mt="16px">
              <Textarea
                h={{ base: '226.5px !important' }}
                resize="none" value={newStream.description} type='text' placeholder='' autoComplete="off" mb='32px' onChange={handleNewStreamDescriptionChange}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
              <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
              <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
          </FormControl>
        </Box>

        <Box w={{sm: '100%'}} pt="8px">
          {newStream.id && <Button variant="submit-md" disabled maxW="120px" onClick={() => handleDeleteStream(newStream)}>Delete</Button>}
          <Button style={{float: 'right'}} variant="submit-md" maxW="120px" onClick={handleCreateStream}>{newStream.id ? 'Confirm' : 'Create'}</Button>
          {!newStream.id && <Button style={{float: 'right', marginRight: '8px'}} variant="ghost-md" maxW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        </Box>
      </Card>
    </>
  );
};

export default StreamEditContent;