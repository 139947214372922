/* eslint-disable */
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
    );
};
/* eslint-enable */

export const validatePassword = (password) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);

  if (!hasUpperCase || !hasLowerCase || !hasNumber || password.length < 8) {
    return false;
  }

  return true;
};

export const validateLoadMore = (data, meta) => {
  if (meta?.total > meta?.offset 
    && meta?.total > meta?.limit
    && data?.length > 1) {
    return true;
  }

  return false;
};

export const user = () => {
  return JSON.parse(localStorage.getItem('auth')) ?? false;
};

export const findGameByPath = (games, pathname) =>  {
  const formattedPathname = pathname.startsWith('/') ? pathname.slice(1) : pathname;
  const matchedRoute = games.find(route => route.path === formattedPathname);
  if (matchedRoute) {
    return matchedRoute.game;
  } else {
    return games[0].game;
  }
};

export const parseNum = (n) => {
  if(0 <= n && n < 10000){
    return n;
  } else if(10000 <= n && n < 1000000) {
    return (Math.floor(n / 1000)) + 'K';
  } else if(1000000 <= n) {
    return (Math.floor(n / 1000000)) + 'M';
  } 
};

export const getInitials = (firstName, lastName) => {
  if (!firstName && !lastName) { 
    return ''; 
  }

  const firstInitial = firstName ? firstName.charAt(0) : '';
  const lastInitial = lastName ? lastName.charAt(0) : '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
};

export const parseDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

export const parseDateTime = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const year = date.getUTCFullYear();
   const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes} ${day}/${month}/${year}`;
};

export const filterDate = (type) => {
  const dates = [];
  let endDate = false;

  if(type === 'This Month'){
    const currentDate = new Date();
    dates.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    endDate = true;
  } else if (type === 'Last Month'){
    const currentDate = new Date();
    dates.push(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
    endDate = true;
  } else if (type === 'Last 3 Month'){
    const currentDate = new Date();
    dates.push(new Date(currentDate.setMonth(currentDate.getMonth() - 3)));
    endDate = true;
  } else if (type === 'This Year'){
    const currentDate = new Date();
    dates.push(new Date(currentDate.setMonth(currentDate.getMonth() - 12)));
    endDate = true;
  }

  if(endDate){
    const endOfDay = new Date();
    dates.push(new Date(endOfDay.setHours(23, 59, 59, 999)));
  }

  return dates;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};