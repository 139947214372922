// Core
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Chakra imports
import {
  Box,
  Image,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';

// Assets
import NavbarLinks from './NavbarLinks';
import logo from 'assets/img/logo.png';

export default function Navbar(props) {
  let navbarShadow = useColorModeValue('0px 1px 4px rgba(0, 0, 0, 0.2)');
  let navbarBg = useColorModeValue('#F0F4FD', 'blue.900');
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let navbarPosition = 'fixed';

  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      border="0.5px solid rgba(9, 155, 255, 0.10)"
      transitionDelay="0s, 0s, 0s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display="flex"
      justifyContent={{ xl: 'center' }}
      mt={secondaryMargin}
      px={{
        sm: paddingX,
        md: '32px',
      }}
      py="7.5px"
      top="0px"
      w={{ sm: '100%' }}
      zIndex="999"
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          <NavLink to="/">
            <Image
              src={logo}
              alt='lmctplus image'
              maxWidth={{ sm: '174px' }}
            />
          </NavLink>
        </Box>
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <NavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            dataUser={props.dataUser}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

Navbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
