// Core
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMinutes, setMinutes, setHours } from 'date-fns';
import { useHistory } from 'react-router-dom';

// Chakra
import { 
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Checkbox,
  Divider,
  Grid,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text, 
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

// Assets
import { formatDate } from 'services/Utils';
import partnerLogo from 'assets/img/partner-logo.jpg';

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import { PartnerActiveIcon } from 'components/Icons/Icons';

const PartnerEditContent = ({ 
  newObject, newObjectErrors, states, tags, categories, setSelectedFile, objects, setNewObject, setNewObjectErrors, handleDeleteObject, handleCreateObject
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#F0F4FD', 'gray.800');
  const paddingTd = '16px 24px 24px 24px';
  const borderColor = useColorModeValue('white', 'white');

  const history = useHistory();
  const handleCancelClick = useCallback(() => {
    setNewObject(null);
    history.push('/partners');
  }, [history, setNewObject]);

  const isValidInputName = useMemo(() => !newObjectErrors.includes('name'), [newObjectErrors]);
  const isValidInputDescription = useMemo(() => !newObjectErrors.includes('description'), [newObjectErrors]);
  const isValidInputDescDescription = useMemo(() => !newObjectErrors.includes('descDescription'), [newObjectErrors]);
  const isValidInputEmail = useMemo(() => !newObjectErrors.includes('email'), [newObjectErrors]);
  const isValidInputLocation = useMemo(() => !newObjectErrors.includes('location'), [newObjectErrors]);
  const isValidInputInstagram = useMemo(() => !newObjectErrors.includes('instagram'), [newObjectErrors]);
  const isValidInputFacebook = useMemo(() => !newObjectErrors.includes('facebook'), [newObjectErrors]);
  const isValidInputWebsite = useMemo(() => !newObjectErrors.includes('website'), [newObjectErrors]);
  const isValidInputYoutube = useMemo(() => !newObjectErrors.includes('youtube'), [newObjectErrors]);
  const isValidInputFrom = useMemo(() => !newObjectErrors.includes('from'), [newObjectErrors]);
  const isValidInputTo = useMemo(() => !newObjectErrors.includes('to'), [newObjectErrors]);
  const isValidInputStatus = useMemo(() => !newObjectErrors.includes('status'), [newObjectErrors]);
  const isValidInputState = useMemo(() => !newObjectErrors.includes('state'), [newObjectErrors]);
  const isValidInputCountry = useMemo(() => !newObjectErrors.includes('country'), [newObjectErrors]);
  const isValidInputDiscountAmount = useMemo(() => !newObjectErrors.includes('discountInfo'), [newObjectErrors]);
  const isValidInputBindCategories = useMemo(() => !newObjectErrors.includes('bindCategories'), [newObjectErrors]);
  const isValidInputBindTags = useMemo(() => !newObjectErrors.includes('bindTags'), [newObjectErrors]);

  const handleNewNameChange = useCallback((e) => { setData(1, e.target.value, 'name'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescriptionChange = useCallback((e) => { setData(10, e.target.value, 'description'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDescDescriptionChange = useCallback((e) => { setData(10, e.target.value, 'descDescription'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewEmailChange = useCallback((e) => { setData(4, e.target.value, 'email'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewLocationChange = useCallback((e) => { setData(10, e.target.value, 'location'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewInstagramChange = useCallback((e) => { setData(10, e.target.value, 'instagram'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewFacebookChange = useCallback((e) => { setData(10, e.target.value, 'facebook'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewWebsiteChange = useCallback((e) => { setData(10, e.target.value, 'website'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewYoutubeChange = useCallback((e) => { setData(10, e.target.value, 'youtube'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleFeaturedChange = useCallback((e) => { setNewObject({...newObject, featured: !newObject.featured }); }, [newObject, setNewObject]);
  const handleShopChange = useCallback((e) => { setNewObject({...newObject, shop: !newObject.shop }); }, [newObject, setNewObject]);
  const handleExclusiveChange = useCallback((e) => { setNewObject({...newObject, exclusive: !newObject.exclusive }); }, [newObject, setNewObject]);
  const handleDeliveryChange = useCallback((e) => { setNewObject({...newObject, delivery: !newObject.delivery }); }, [newObject, setNewObject]);
  const handleAffiliateChange = useCallback((e) => { setNewObject({...newObject, affiliate: !newObject.affiliate }); }, [newObject, setNewObject]);
  const handleNewFromChange = useCallback((e) => { setData(0, e.target.value, 'from'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewToChange = useCallback((e) => { setData(0, e.target.value, 'to'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewStatusChange = useCallback((e) => { setData(0, e.target.value, 'status'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewStateChange = useCallback((e) => { setData(2, e.target.value, 'state'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewCountryChange = useCallback((e) => { setData(3, e.target.value, 'country'); }, [setNewObject, newObject, setNewObjectErrors, newObjectErrors]);
  const handleNewDiscountAmount = useCallback((e, planKey, field) => {
    const { value } = e.target;
    const parsedValue = isNaN(value) ? value : Number(value);

    setNewObject((prev) => {
      if (planKey === 'title' || planKey === 'discount') {
        return {
          ...prev,
          discountInfo: {
            ...prev.discountInfo,
            [field]: parsedValue
          }
        };
      } else {
        return {
          ...prev,
          discountInfo: {
            ...prev.discountInfo,
            plans: {
              ...prev.discountInfo.plans,
              [planKey]: {
                ...prev.discountInfo.plans[planKey],
                [field]: field === 'isCurrency' ? (value === 'true') : parsedValue
              }
            }
          }
        };
      }
    });

    if (parsedValue === 0) {
      setNewObjectErrors((prevErrors) => [...prevErrors, field]);
    } else {
      setNewObjectErrors((prevErrors) => prevErrors.filter((error) => error !== field));
    }
  }, [setNewObject, setNewObjectErrors]);
  
  const handleNewCategoriesChange = useCallback((object) => {
    setNewObject((prev) => {
      const bindCategories = Array.isArray(prev.bindCategories) ? prev.bindCategories : [];
      const isAlreadySelected = bindCategories.some((category) => category.id === object.id);
      const updatedBindCategories = isAlreadySelected
        ? bindCategories.filter((category) => category.id !== object.id)
        : [...bindCategories, object]; 

      return {
        ...prev,
        bindCategories: updatedBindCategories,
      };
    });
  }, [setNewObject]);

  const handleNewTagsAmount = useCallback((object) => {
    setNewObject((prev) => {
      const bindTags = Array.isArray(prev.bindTags) ? prev.bindTags : [];
      const isAlreadySelected = bindTags.some((tag) => tag.id === object.id);
      const updatedBindTags = isAlreadySelected
        ? bindTags.filter((tag) => tag.id !== object.id)
        : [...bindTags, object]; 

      return {
        ...prev,
        bindTags: updatedBindTags,
      };
    });
  }, [setNewObject]);

  const setData = (maxValue, target, key) => {
    if (target.length < maxValue) {
      setNewObjectErrors([...newObjectErrors, key]);
    } else {
      setNewObjectErrors(newObjectErrors.filter(error => error !== key));
    }
    setNewObject({...newObject, [key]: target}); 
  };

  const handleClickImage = () => {
    document.getElementById('fileInput').click(); 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large! Maximum size is 5 MB.');
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      alert('Unsupported file type! Only JPG and PNG are allowed.');
      return;
    }

    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewObject({ ...newObject, image: reader.result }); 
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box zIndex="9" position="relative">
      {
        newObject.id ? (
          <Breadcrumbs icon={PartnerActiveIcon} target='Partners' url='#/partners' current='Partner' />
        ) : (
          <Breadcrumbs icon={PartnerActiveIcon} target='Partners' url='#/partners' current='New Partner' />
        )
      }

      <Flex gap="16px" position="absolute" mt="-92px" right="0">
        {!newObject.id && <Button variant="ghost-md" maxW="120px" onClick={() => handleCancelClick()}>Cancel</Button>}
        {newObject.id && <Button variant="submit-md-delete" disabled w="120px" onClick={() => handleDeleteObject(newObject)}>Delete</Button>}
        <Button  variant="submit-md" w="120px" onClick={handleCreateObject}>{newObject.id ? 'Confirm' : 'Create'}</Button>
      </Flex>

      <Grid templateColumns={{ sm: '1fr', lg: '3fr 1fr' }} templateRows='1fr' gap="24px">
        <Box>
          <Card padding="24px" mb="40px">
            <Flex gap="24px">
              <Image 
                src={newObject.image ? newObject.image : partnerLogo} 
                borderRadius="10px"
                objectFit="cover"
                w="160px" 
                h="107px" />
              <Box>
                <Text 
                  variant="title" 
                  color="#099BFF" 
                  cursor="pointer" 
                  onClick={handleClickImage}>
                  { newObject.image ? 'Change picture' : 'Add picture' }
                </Text>

                <Flex gap="3px">
                  <Text variant="text-md">Name:</Text>
                  <Text variant="text-md" fontWeight="500">Square</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Format:</Text>
                  <Text variant="text-md" fontWeight="500">3:2</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Maximum size:</Text>
                  <Text variant="text-md" fontWeight="500">5 mb</Text>  
                </Flex>

                <Flex gap="3px">
                  <Text variant="text-md">Supporting Image Type:</Text>
                  <Text variant="text-md" fontWeight="500">JPG, PNG</Text>  
                </Flex>
                
              </Box>
            </Flex>

            <input
              id="fileInput"
              type="file"
              accept=".jpg,.jpeg,.png"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </Card>

          <Text variant="title-lg" mb="12px">Partner info</Text>
          <Card padding="24px" mb="8px">
            <Flex gap="24px">
              <FormControl variant="floating" id="name">
                <Input value={newObject.name} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewNameChange}  className={isValidInputName ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="name" className={isValidInputName ? 'input-valid' : 'input-invalid'}>Title</FormLabel>
                <FormHelperText className={isValidInputName ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="email">
                  <Input value={newObject.email} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewEmailChange}  className={isValidInputEmail ? 'input-valid' : 'input-invalid'}  />
                  <FormLabel htmlFor="email" className={isValidInputEmail ? 'input-valid' : 'input-invalid'}>Email</FormLabel>
                  <FormHelperText className={isValidInputEmail ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>
            </Flex>

            <Flex gap="24px">
              <FormControl variant="floating" id="description">
                <Textarea
                    h={{ base: '150px !important' }}
                    resize="none" value={newObject.description} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewDescriptionChange}  className={isValidInputDescription ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="description" className={isValidInputDescription ? 'input-valid' : 'input-invalid'}>Description</FormLabel>
                <FormHelperText className={isValidInputDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="descDescription">
                <Textarea
                    h={{ base: '150px !important' }}
                    resize="none" value={newObject.descDescription} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewDescDescriptionChange}  className={isValidInputDescDescription ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="descDescription" className={isValidInputDescDescription ? 'input-valid' : 'input-invalid'}>Long Description</FormLabel>
                <FormHelperText className={isValidInputDescDescription ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>
            </Flex>

            <Flex gap="24px">
              <FormControl variant="floating" id="location">
                <Input value={newObject.location} type='text' placeholder='' autoComplete="off" onChange={handleNewLocationChange}  className={isValidInputLocation ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="location" className={isValidInputLocation ? 'input-valid' : 'input-invalid'}>Location (Address)</FormLabel>
                <FormHelperText className={isValidInputLocation ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="country">
                <Input value="Australia" disabled type='text' placeholder='' autoComplete="off" onChange={handleNewCountryChange}  className={isValidInputCountry ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="country" className={isValidInputCountry ? 'input-valid' : 'input-invalid'}>Country</FormLabel>
                <FormHelperText className={isValidInputCountry ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating">
                  <Select placeholder="State" value={newObject.state} onChange={handleNewStateChange} className={isValidInputState ? 'input-valid' : 'input-invalid'}>
                      { states && states.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                  </Select>
                  <FormHelperText className={isValidInputState ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

            </Flex>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Activities</Text>
          <Card padding="24px" w="100%">
            <Flex gap="24px">
              <FormControl variant="floating">
                  <Select placeholder="Status" value={newObject?.status} onChange={handleNewStatusChange} className={isValidInputStatus ? 'input-valid' : 'input-invalid'}>
                      <option value="pending">Pending</option>
                      <option value="published">Published</option>
                      <option value="draft">Draft</option>
                      <option value="trash">Trash</option>
                  </Select>
                  <FormHelperText className={isValidInputStatus ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating">
                <Input value={
                  newObject?.from ? formatDate(newObject?.from) : null
                } type='datetime-local' onChange={handleNewFromChange} className={isValidInputFrom ? 'input-valid' : 'input-invalid'}/>
                <FormLabel className={isValidInputFrom ? 'input-valid' : 'input-invalid'}>From</FormLabel>
                <FormHelperText className={isValidInputFrom ? 'hidden' : 'input-error' }>From</FormHelperText>
              </FormControl>

              <FormControl variant="floating">
                <Input value={
                  newObject?.to ? formatDate(newObject?.to) : null
                } type='datetime-local' onChange={handleNewToChange} className={isValidInputTo ? 'input-valid' : 'input-invalid'}/>
                <FormLabel className={isValidInputTo ? 'input-valid' : 'input-invalid'}>To</FormLabel>
                <FormHelperText className={isValidInputTo ? 'hidden' : 'input-error' }>To</FormHelperText>
              </FormControl>
            </Flex>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Social</Text>
          <Card padding="24px" mb="8px">
            <Flex gap="24px">
              <FormControl variant="floating" id="instagram">
                <Input value={newObject.instagram} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewInstagramChange}  className={isValidInputInstagram ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="instagram" className={isValidInputInstagram ? 'input-valid' : 'input-invalid'}>Instagram</FormLabel>
                <FormHelperText className={isValidInputInstagram ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="facebook">
                <Input value={newObject.facebook} type='text' placeholder='' autoComplete="off" mb='28px' onChange={handleNewFacebookChange}  className={isValidInputFacebook ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="facebook" className={isValidInputFacebook ? 'input-valid' : 'input-invalid'}>Facebook</FormLabel>
                <FormHelperText className={isValidInputFacebook ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>
            </Flex>

            <Flex gap="24px">
              <FormControl variant="floating" id="website">
                <Input value={newObject.website} type='text' placeholder='' autoComplete="off" onChange={handleNewWebsiteChange}  className={isValidInputWebsite ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="website" className={isValidInputWebsite ? 'input-valid' : 'input-invalid'}>Website</FormLabel>
                <FormHelperText className={isValidInputWebsite ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>

              <FormControl variant="floating" id="youtube">
                <Input value={newObject.youtube} type='text' placeholder='' autoComplete="off" onChange={handleNewYoutubeChange}  className={isValidInputYoutube ? 'input-valid' : 'input-invalid'}  />
                <FormLabel htmlFor="youtube" className={isValidInputYoutube ? 'input-valid' : 'input-invalid'}>Youtube</FormLabel>
                <FormHelperText className={isValidInputYoutube ? 'hidden' : 'input-error' }>Input invalid</FormHelperText>
              </FormControl>
            </Flex>
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Discount</Text>
          <Card padding="24px" mb="8px">
            <Flex gap="16px" mb="16px">
              <FormControl variant="floating">
                <Input 
                  type="text" 
                  value={newObject?.discountInfo?.title} 
                  onChange={(e) => handleNewDiscountAmount(e, 'title', 'title')}
                  name="title" />
                <FormLabel>General Title</FormLabel>
              </FormControl>

              <FormControl variant="floating" w="20%">
                <Input 
                  type="text"
                  value={newObject?.discountInfo?.discount} 
                  onChange={(e) => handleNewDiscountAmount(e, 'discount', 'discount')}
                  name="generalDiscount" />
                <FormLabel>Discount</FormLabel>
              </FormControl>
            </Flex>

            {newObject?.discountInfo?.plans && Object.entries(newObject.discountInfo.plans).map(([key, item], index) => (
              <Flex key={index} gap="24px" mb="16px">
                <FormControl variant="floating" id={`${key}_description`}>
                  <Input 
                    value={item.description} 
                    type='text' 
                    name="description"
                    onChange={(e) => handleNewDiscountAmount(e, key, 'description')}
                  />
                  <FormLabel>Description {key}</FormLabel>
                </FormControl>

                <Flex w="100%">
                  <FormControl variant="floating" id={`${key}_code`}>
                    <Input
                      value={item.code} 
                      type='text' 
                      name="code"
                      onChange={(e) => handleNewDiscountAmount(e, key, 'code')}
                    />
                    <FormLabel>Promocode</FormLabel>
                  </FormControl>
                </Flex>

                <Flex w="60%">
                  <FormControl variant="floating" id={`${key}_discount`}>
                    <Input 
                      borderRadius="10px 0 0 10px !important"
                      type='number' 
                      value={item.discount} 
                      name="discount"
                      onChange={(e) => handleNewDiscountAmount(e, key, 'discount')}
                    />
                    <FormLabel>Discount</FormLabel>
                  </FormControl>

                  <Select
                    borderRadius="0 10px 10px 0 !important"
                    w="45%"
                    value={item.isCurrency} 
                    onChange={(e) => handleNewDiscountAmount(e, key, 'isCurrency')}
                  >
                    <option value="false">%</option>
                    <option value="true">$</option>
                  </Select>
                </Flex>
              </Flex>
            ))}
          </Card>

          <Text variant="title-lg" mt="40px" mb="12px">Options</Text>
          <Card padding='8px 0' my={{ sm: '22px', xl: '0px' }}>
            <Table>
                <Tbody>
                  <Tr>
                    <Td verticalAlign="top" p={paddingTd} w={{sm: '20%'}}>
                      <Text variant="title">Tags</Text>
                    </Td>
                    <Td verticalAlign="top" p={paddingTd}>
                      <Flex wrap="wrap">
                        {tags?.map((option) => (
                          <Checkbox
                            pb="16px"
                            key={option.id}
                            isChecked={ 
                              newObject?.bindTags?.some(tag => tag.id === option.id) 
                            }
                            onChange={() => handleNewTagsAmount(option)}
                            w="33%"
                          >
                            {option.name}
                          </Checkbox>
                        ))}
                      </Flex>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td verticalAlign="top" p={paddingTd} w={{sm: '20%'}}>
                      <Text variant="title">Options</Text>
                    </Td>
                    <Td verticalAlign="top" p={paddingTd}>
                      <Grid templateColumns={{ sm: '1fr', lg: '1fr 1fr' }} templateRows='1fr' gap="24px">
                        <Box>
                          <Flex gap="8px">
                            <Text variant="title">Featured</Text>
                            <Switch float='right' isChecked={newObject?.featured} onChange={handleFeaturedChange} />
                          </Flex>
                          <Flex gap="8px" mt="16px">
                            <Text variant="title">Shop</Text>
                            <Switch float='right' isChecked={newObject?.shop} onChange={handleShopChange} />
                          </Flex>
                          <Flex gap="8px" mt="16px">
                            <Text variant="title">Exclusive</Text>
                            <Switch float='right' isChecked={newObject?.exclusive} onChange={handleExclusiveChange} />
                          </Flex>
                        </Box>

                        <Box>
                          <Flex gap="8px" mt="16px">
                            <Text variant="title">Delivery</Text>
                            <Switch float='right' isChecked={newObject?.delivery} onChange={handleDeliveryChange} />
                          </Flex>
                          <Flex gap="8px" mt="16px">
                            <Text variant="title">Affiliate</Text>
                            <Switch float='right' isChecked={newObject?.affiliate} onChange={handleAffiliateChange} />
                          </Flex>
                        </Box>
                      </Grid>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td verticalAlign="top" p={paddingTd} w={{sm: '20%'}} border="unset">
                      <Text variant="title">Categories</Text>
                    </Td>
                    <Td verticalAlign="top" p={paddingTd} border="unset">
                      <Flex wrap="wrap">
                        { categories?.map((option) => (
                          <Checkbox
                            key={option.id}
                            pb="16px"
                            isChecked={
                              newObject?.bindCategories?.some(category => category.id === option.id) 
                            }
                            onChange={() => handleNewCategoriesChange(option)}
                            w="25%"
                          >
                            {option.name}
                          </Checkbox>
                        ))}
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
          </Card>
        </Box>

        <Box w="100%">
          
        </Box>
      </Grid>
    </Box>
  );
};

export default PartnerEditContent;