// Core
import { HiDotsVertical } from 'react-icons/hi';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { getInitials, parseNum } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { SearchIcon } from 'components/Icons/Icons';
import NotFound from 'views/Dashboard/404.js';

const UserRow = ({ 
	dataUsers, 
	handleUserDataOpen,
	userMeta,
    loadMore,
    handleSearch,
    search
}) => {

  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('white', 'rgba(255, 255, 255, 0.10)');
  const paddingTd = '8px 0 16px 24px';

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='0' >Users</Text>
  	<Divider opacity="1" mr="2px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
  	<FormControl variant="search" position="absolute" right="0" top="80px" mr="31px" maxW="326px" className={search?.length > 0 ? 'search-active' : ''}>
      <InputGroup>
	    <InputLeftElement pointerEvents='none'>
	      <SearchIcon fontSize="24px" ml="8px" />
	    </InputLeftElement>
	    <Input type='text' placeholder='Search' onChange={handleSearch} />
	  </InputGroup>
    </FormControl>
      {dataUsers.length > 0 ? (
	    <Card padding="8px 0 0 0" overflow="hidden">
	      <CardBody >
		      <Table color={textColor}>
			      <Thead>
			          <Tr>
			            <Td><Text variant="title-md" p='0 0 6px 0'>Name</Text></Td>
			            <Td><Text variant="title-md" p='0 0 6px 0'>Status</Text></Td>
			            <Td><Text variant="title-md" p='0 0 6px 0'>Email</Text></Td>
			            <Td><Text variant="title-md" p='0 0 6px 0'>Phone</Text></Td>
			            <Td><Text variant="title-md" p='0 0 6px 0'>Spent</Text></Td>
			            <Td><Text variant="title-md" p='0 0 6px 0'>Balance</Text></Td>
			          </Tr>
			      </Thead>
		          <Tbody>
		            {dataUsers.map((row) => {
		              return (
		                <Tr key={row.id} border="unset" className="cursor-tr">
					      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="top" p={paddingTd} borderColor={borderColor}>
					      	<Flex position="relative">
					            <Text variant="text" color={textColor} pt="10px">{row.firstName} {row.lastName}</Text>
					      	</Flex>
					      </Td>
					      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="top" p={paddingTd} borderColor={borderColor}>
					        <Text variant="text-md" 
					        	className={
					        		row.isBlocked ? 'tag-red' : row.isConfirm ? 'tag-green' : 'tag-orange'
					        	}
					        >{row.isBlocked ? 'Blocked' : row.isConfirm ? 'Activated' : 'Not Activated'}</Text>
					      </Td>
					      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="top" p={paddingTd} borderColor={borderColor}>
					        <Text color={textColor} variant="text" pt="10px">{row.email}</Text>
					      </Td>
					      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="top" p={paddingTd} borderColor={borderColor}>
					        <Text color={textColor} variant="text" pt="10px">+{row.phone}</Text>
					      </Td>
					      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="top" p={paddingTd} borderColor={borderColor}>
					        <Text color={textColor} variant="text" pt="10px" className={row.spent > 0 ? 'winner-text-color' : 'loser-text-color'}>${parseNum(row.spent / 100)}</Text>
					      </Td>
					      <Td onClick={() => handleUserDataOpen(row)} cursor="pointer" verticalAlign="top" p={paddingTd} borderColor={borderColor}>
					        <Text color={textColor} variant="text" pt="10px" className={row.balance > 0 ? 'winner-text-color' : 'loser-text-color'}>${parseNum(row.balance / 100)}</Text>
					      </Td>
					    </Tr>
		              );
		            })}
		          </Tbody>
		      </Table>
	      </CardBody>

		  { userMeta?.total > userMeta?.offset 
	            && userMeta?.total > userMeta?.limit
	            && dataUsers?.length > 1 
	            && (
	        <Flex justifyContent="center" m="22px 0 32px 0">  
	          <Button w="fit-content" mt="10px" pl="40px" pr="40px" me="16px" variant="ghost" color='blue.200' onClick={loadMore}>
	            More
	          </Button>
	        </Flex>
	      )}
	    </Card>
      ) : (
	  	<NotFound text="Users not found" />
	  )}
  	 </>
  );
};

export default UserRow;