// Core
import { HiDotsVertical } from 'react-icons/hi';
import {useEffect, useState, useCallback} from 'react';
import { IoTicketSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';
import { AiOutlineDelete } from 'react-icons/ai';

// Chakra
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Link,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { parseDateTime } from 'services/Utils';
import { ReactComponent as InfoControls } from 'assets/svg/controls.svg';
import partnerLogo from 'assets/img/partner-logo.jpg';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { TicketIcon, AdminsActiveIcon } from 'components/Icons/Icons';

const MembershipRow = ({ data, handleEditOpen, handleActiveChange }) => {
  const [switched, setSwitched] = useState(data.hidden);

  const handleHidden = (event) => { 
    setSwitched(switched ? false : true);
  };

  /*useEffect(() => { 
    if(data && data.hidden !== switched){
      apiService.updatePlan({...data, hidden: switched});
    }
  }, [switched]);*/

  return (
    <Card key={data.id} padding="16px">
      <CardBody>
        <Box w="100%">

          <Flex justifyContent="space-between" width="100%">
            <Flex gap="12px">
              <Image 
                src={partnerLogo} 
                borderRadius="12px"
                objectFit="cover"
                w="50px" 
                h="50px" />
              <Box mt="-4px">
                <Text variant="title" fontSize="24px" fontWeight="700">{data?.name}</Text>
                <Text variant="title" fontSize="17px" mt="-5px" >${data.price / 100}</Text>
              </Box>
            </Flex>

            <Box float="right" m="-7px -16px 0px 0px">
              <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HiDotsVertical />}
                    variant="ghost"
                    color="white"
                    fontSize="20px"
                  />
                  <MenuList className="context-menu">
                    <MenuItem>Change</MenuItem>
                    <MenuItem>Delete</MenuItem>
                  </MenuList>
              </Menu>
            </Box>
          </Flex>

          <Flex gap="8px" mt="8px">
            <Text variant="text-md">Created:</Text>
            <Text variant="title-md">{ parseDateTime(data.createdAt) }</Text>
          </Flex>

          <Flex justifyContent="space-between" w="100%" mt="24px">
            <Flex
              borderRadius="10px"
              bg="rgba(34, 151, 63, 0.20)"
              p="4px 8px"
              gap="4px"
            >
              <AdminsActiveIcon fontSize="14px" color="#57D175" mt="3px" />
              <Text variant="text-md" fontWeight="500" color="#57D175">0</Text>
            </Flex>

            <Flex justifyContent="space-between" w="100%" gap="8px" maxW="84px" mt="4px">
              <Text variant="text-md">Active</Text> 
              <Switch
                colorscheme="teal"
                m="0px 0 0 -4px"
                isChecked={!switched}
                onChange={handleHidden} />
            </Flex>
          </Flex>

        </Box>
      </CardBody>
    </Card>
  );
};

export default MembershipRow;