// Chakra imports
import { QuestionIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

// Assets
import IconBox from 'components/Icons/IconBox';

export function SidebarHelp(props) {
  // Pass the computed styles into the `__css` prop
  return (
    <Flex
      borderRadius="15px"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="start"
      boxSize="border-box"
      p="16px"
      h="120px"
      w="100%"
    >
      <IconBox width="35px" h="35px" bg="white" mb="auto">
        <QuestionIcon color="teal.300" h="18px" w="18px" />
      </IconBox>
      <Text fontSize="sm" color="white" mb="20px" fontWeight="bold">
        Need help?
      </Text>
    </Flex>
  );
}
