// Core
import { HiDotsVertical } from 'react-icons/hi';
import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Components
import {
  ArrowIcon,
  DeleteIcon,
  EditIcon
} from 'components/Icons/Icons';
import EditModal from './CategoryModal';
import Attention from 'components/Modals/Attention.js';

const CategoryRow = ({
  row,
  handleShowOnLeftChange,
  handleFeaturedChange,
  handleDeleteObject,
  handleDataOpen
}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const paddingTd = '8px 0 16px 24px';
  const paddingTdSub = '8px 0 16px 0';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(!show);
  });

  const attentionModal = async () => {
    onOpen();
  };

  return (
    <>
      <Tr key={row.id} border="unset">
        <Td w="30%" verticalAlign="top" p={paddingTd}>
          <Flex gap="12px" pl="18px">
            <ArrowIcon 
              onClick={handleShow}
              cursor="pointer"
              opacity={row.subCategories.length > 0 ? '1' : '0.2'}
              key={row.id} mt="7px" transform={!show ? 'rotate(180deg)' : 'rotate(0deg)'} fontSize="24px" color="white"/>
            <Text variant="text" pt="10px" color={textColor}>{row.name}</Text>
          </Flex>
        </Td>
        <Td verticalAlign="top" p={paddingTd}>
          <Text variant="text" pt="10px" color={textColor}>{row.subCategories.length}</Text>
        </Td>
        <Td verticalAlign="center">
              <Switch isChecked={row?.featured} onChange={() => handleFeaturedChange(row)} />
        </Td>
        <Td verticalAlign="center">
          <Switch isChecked={row?.showOnLeft} onChange={() => handleShowOnLeftChange(row)} />
        </Td>
        <Td>
          <Flex gap="36px" justifyContent="flex-end" pr="10px">
            <EditIcon fontSize="24" color="white" onClick={() => handleDataOpen(row)} cursor="pointer" />
            <DeleteIcon fontSize="24" color="white" onClick={attentionModal} cursor="pointer" />

            <Attention
              variant="remove"
              handle={() => handleDeleteObject(row)}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Flex>
        </Td>
      </Tr>

      { row.subCategories && show && (
        <Tr key={`${row.id}-subcategories`} >
          <Td colSpan={5} p={0} w="100%" border="unset" >
            <Table color={textColor} ml="54px" w="calc(100% - 54px)" borderLeft="1px solid rgba(255, 255, 255, 0.10)">
              <Tbody>
                {row.subCategories.map((subRow) => (
                  <Tr key={subRow.id} border="unset">
                    <Td w="28.5%" verticalAlign="top" p={paddingTdSub}>
                      <Text pl="24px" variant="text" pt="10px" color={textColor}>{subRow.name}</Text>
                    </Td>
                    <Td verticalAlign="top" p={paddingTdSub}>
                      <Text variant="text" pt="10px" color={textColor}>{subRow.parentId ? row.name : ''}</Text>
                    </Td>
                    <Td>
                      <Flex gap="36px" justifyContent="flex-end" pr="10px">
                      <EditIcon fontSize="24" color="white" cursor="pointer" onClick={() => handleDataOpen(subRow)} />
                      <DeleteIcon fontSize="24" color="white" cursor="pointer" onClick={() => handleDeleteObject(subRow)} />
                    </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default CategoryRow;