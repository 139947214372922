// Core
import { useState, useEffect, useCallback } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { getInitials } from 'services/Utils';
import { parseDateTime } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import NotFound from 'views/Dashboard/404.js';
import MembershipRow from './components/MembershipRow';

const Memberships = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  const history = useHistory();

  const onNewButtonClicked = useCallback(() => {
    history.push('/membership/new');
  }, [history]);

  const onRowClicked = useCallback((membership) => {
    history.push(`/membership/${membership.id}`);
  }, [history]);

  const { data, isLoading } = useQuery({
    queryKey: ['data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiService.getMemberships();
      return response.data;
    }
  });

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor}>Subscription</Text>
      <Divider opacity="1" mr="2px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
      
      { data?.length > 0 ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing="8px">
          {data?.map(item => (
            <MembershipRow handleEditOpen={onRowClicked} key={item.id} data={item} />
          ))}
        </SimpleGrid>
        ) : (
          <NotFound text="Not Found" />
        )
      }    
    </>
  );
};

export default Memberships;