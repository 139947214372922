// Core
import { useState, useEffect, useCallback } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

// Chakra 
import { 
  Box,
  Button,
  Divider,
  Flex, 
  FormControl,
  FormLabel,
  Input,
  Image,
  Text, 
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Textarea,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ReactComponent,
  SimpleGrid,
  Select,
  Switch,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

// Assets
import { apiService } from 'services/ApiService';
import { getInitials } from 'services/Utils';
import { parseDateTime } from 'services/Utils';

// Components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import StreamRow from './components/StreamRow';
import NotFound from 'views/Dashboard/404.js';

const Stream = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('blue.50', 'rgba(255, 255, 255, 0.10)');

  const [offset, setOffset] = useState(0); 
  const [streams, setStreams] = useState([]);  
  const [streamsMeta, setStreamsMeta] = useState(); 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const onNewButtonClicked = useCallback(() => {
    history.push('/stream/new');
  }, [history]);

  const onRowClicked = useCallback((stream) => {
    history.push(`/stream/${stream.id}`);
  }, [history]);

  const { isLoading: isLoading, refetch } = useQuery({
    queryKey: ['streams', offset],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const data = {
        limit: 250, 
        offset
      };

      const response = await apiService.getStreams(data);
      if(offset === 0){
        setStreams(response.data);
      } else {
        setStreams([...streams, ...response.data]);
      }
      
      setStreamsMeta(response.meta);
      return true;
    }
  });

  const loadMore = async () => {
    setOffset(offset + 25);
  };

  const handleDelete = async (stream) => {
    try {
      await apiService.deleteStream({id: stream.id});
      setOffset(0);
      refetch();
    } catch (error) {}
  };

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='0px' className="page-name">Stream</Text>
      <Divider opacity="1" mr="2px" borderColor={borderColor} borderTopWidth="1px" borderBottomWidth="0" mb='32px'/>
      <Button variant="submit-ms" position="absolute" right="0" top="80px" mr="31px" maxW="150px" onClick={onNewButtonClicked} leftIcon={<FaPlus />}>Stream</Button>
      { streams.length > 0 ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="8px">
          {streams?.map(stream => (
            <StreamRow handleEditOpen={onRowClicked} key={stream.id} stream={stream} handleDelete={handleDelete} />
          ))}
        </SimpleGrid>
        ) : (
          <NotFound text="Not Found" />
        )
      }    
    </>
  );
};

export default Stream;