// Core
import { useCallback } from 'react';
import { HiDotsVertical } from 'react-icons/hi';

// Chakra
import { Text, Tr, Td, Image, Box, Menu, MenuItem, MenuList, MenuButton, IconButton} from '@chakra-ui/react';

// Assets
import partnerLogo from 'assets/img/partner-logo.jpg';

// Components
import { LinkIcon } from 'components/Icons/Icons';

const BannerRow = ({ banner, handleEditBannerOpen, onDeleteBannerOpen }) => {
  const handleDeleteBannerOpen = useCallback(() => {
    onDeleteBannerOpen(banner);
  }, [banner, onDeleteBannerOpen]);

  if (!banner) {return null;}

  return (
    <Tr className="cursor-tr">
      <Td onClick={() => handleEditBannerOpen(banner)} pr="0px" maxW={{sm: '220px'}}>
        <Image src={ banner.image ? banner.image : partnerLogo } 
          h="73px" w="236px" objectFit="cover" borderRadius="10px" border="1px solid #091931" />
      </Td>
      <Td pl="0px" onClick={() => handleEditBannerOpen(banner)}>
        { banner.title }
      </Td>
      <Td textTransform="capitalize" onClick={() => handleEditBannerOpen(banner)}>{banner.type} {banner.objectType ? ' / ' + banner.objectType : ''}</Td>
      <Td onClick={() => handleEditBannerOpen(banner)}>
        <Text variant="sub-text" w="100px" 
          className={
            !banner.hidden ? 'tag-green' : 'tag-orange'
          }
        >{!banner.hidden ? 'Active' : 'Not active'}</Text>
      </Td>
      <Td w='120px'>
        <Box float="right" m="0">
          <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                color="white"
                icon={<HiDotsVertical />}
                variant="ghost"
                fontSize="20px"
              />
              <MenuList className="context-menu">
                <MenuItem onClick={() => handleEditBannerOpen(banner)}>Change</MenuItem>
                <MenuItem onClick={() => handleDeleteBannerOpen()}>Delete</MenuItem>
              </MenuList>
          </Menu>
        </Box>
      </Td>
    </Tr>
  );
};

export default BannerRow;