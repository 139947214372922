// Core
import { useState } from 'react';
import { 
  Link, 
  Route, 
  Switch, 
  Redirect, 
  useRouteMatch, 
  useLocation 
} from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';

// Chakra imports
import { 
  Box, 
  Button,
  Divider,
  Image,
  Flex, 
  FormControl,
  InputGroup,
  InputLeftElement,
  Text,
  Input,
  useColorModeValue
} from '@chakra-ui/react';

// Components
import Card from 'components/Card/Card.js';
import { SearchIcon } from 'components/Icons/Icons';

function Receipts(props) {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  const textColor = useColorModeValue('blue.800', 'white');
  const borderColor = useColorModeValue('rgba(255, 255, 255, 0.10)', 'rgba(255, 255, 255, 0.10)');

  const [search, setSearch] = useState(); 

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Text fontSize='40px' fontStyle='normal' fontWeight='700' color={textColor} mb='12px'>Receipts</Text>
      <Flex position="absolute" right="0" top="80px" mr="31px" gap="12px">
        <FormControl variant="search" maxW="326px" className={search?.length > 0 ? 'search-active' : ''}>
          <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon fontSize="24px" ml="8px" />
          </InputLeftElement>
          <Input type='text' placeholder='Search' onChange={handleSearch} />
        </InputGroup>
        </FormControl>
      </Flex>

      <Box mt="0px" mb="50px" direction='column'>
        <Flex align='left' mb={{ sm: '10px', md: '0px' }}
          direction={{ sm: 'column', md: 'row' }}
          w={{ sm: '100%' }}
          textAlign={{ sm: 'center', md: 'start' }}
          borderBottom="1px"
          borderColor={borderColor}
          className="settings-tabs">
          <Link to={`${url}/all`} className={isActive(`${path}/all`) ? 'settings-tab-active' : ''}>All</Link>
          <Link to={`${url}/new`} className={isActive(`${path}/new`) ? 'settings-tab-active' : ''}>New</Link>
          <Link to={`${url}/active`} className={isActive(`${path}/active`) ? 'settings-tab-active' : ''}>Active</Link>
          <Link to={`${url}/done`} className={isActive(`${path}/done`) ? 'settings-tab-active' : ''}>Done</Link>
        </Flex>
      </Box>

      <Box align='left' mt='32px'>
        <Switch>
          <Route path={`${path}/all`} render={() => <Text variant="text">Not found page</Text>} />
          <Route path={`${path}/new`} render={() => <Text variant="text">Not found page</Text>} />
          <Route path={`${path}/active`} render={() => <Text variant="text">Not found page</Text>} />
          <Route path={`${path}/done`} render={() => <Text variant="text">Not found page</Text>} />
          <Redirect from={`${path}*`} to={`${path}/all`} />
        </Switch>
      </Box>
    </>
  );
}

export default Receipts;