import { user } from 'services/Utils';

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

async function fetchAPI(path, options = {}) {
  let queryString = '';
  if (options.params) {
    const searchParams = new URLSearchParams(options.params);
    queryString = `?${searchParams.toString()}`;
    delete options.params;
  }

  const url = `${API_BASE_URL}${path}${queryString}`;

  const headers = options.body instanceof FormData ? options.headers : {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  const response = await fetch(url, {
    ...options,
    headers
  });

  if (response.status === 401) {
    localStorage.removeItem('auth');
    window.location.href = '/#/auth/signin';
  }

  if (!response.ok) {
    throw new Error(`API call failed: ${url}, status: ${response.status}`);
  }

  return response.json();
}

function userAuth(){
  return { 'Authorization': `Bearer ${user().token}`};
}

export const apiService = {
  /* General APIs */

  // auth
  async recoverPassword(data) { return fetchAPI('/auth/recover-password', { method: 'POST', body: JSON.stringify(data)});},
  async recoverPasswordConfirm(data) { return fetchAPI('/auth/recover-password-confirm', { method: 'POST', body: JSON.stringify(data)});},
  async login(data) { return fetchAPI('/auth/login', { method: 'POST', body: JSON.stringify(data)});},
  async authTwoFA(data) { return fetchAPI('/auth/twofa', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  /**/ async refreshToken(data) { return fetchAPI('/auth/refresh-token', { method: 'POST', body: JSON.stringify(data)});},
  async logout() { return fetchAPI('/auth/logout', { method: 'POST', headers: userAuth()});},
  // auth sessions
  async getSessions(params) { return fetchAPI('/auth/sessions', { method: 'GET', params, headers: userAuth()});},
  async deleteAllSessions() { return fetchAPI('/auth/sessions', { method: 'DELETE', headers: userAuth()});},
  async deleteSession(params) { return fetchAPI(`/auth/sessions/${params.sessionId}`, { method: 'DELETE', headers: userAuth()});},
  // users and profile
  async getMe() { return fetchAPI('/users/me', { method: 'GET', headers: userAuth()});},
  async updateUser(data) { return fetchAPI('/users/me', { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  async updateUserEmail(data) { return fetchAPI('/users/change-email', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updateUserPassword(data) { return fetchAPI('/users/change-password', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updateUserPhone(data) { return fetchAPI('/users/change-phone', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async deleteUser(data) { return fetchAPI('/users/delete', { method: 'DELETE', body: JSON.stringify(data), headers: userAuth()});},
  async getNotifications(params) { return fetchAPI('/notifications', { method: 'GET', params, headers: userAuth()});},
  async getNotificationsCount() { return fetchAPI('/notifications/count', { method: 'GET', headers: userAuth()});},
  /**/ async deleteNotification(params) { return fetchAPI(`/notifications/${params.notificationId}`, { method: 'DELETE', headers: userAuth()});},
  /**/ async readNotification(params) { return fetchAPI(`/notifications/${params.notificationId}`, { method: 'PATCH', headers: userAuth()});},
  /**/ async readAllNotifications() { return fetchAPI('/notifications', { method: 'PATCH', headers: userAuth()});},
  async userTwoFAList() { return fetchAPI('/twofa', { method: 'GET', headers: userAuth()});},
  async userTwoFA(data) { return fetchAPI('/twofa/' + data.type, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  
  /* Admin APIs */

  // upload other media
  async uploadImage(data) { return fetchAPI('/admin/upload', { method: 'POST', body: data, headers: userAuth()});},

  // partners
  async getPartners(params) { return fetchAPI('/admin/partners', { method: 'GET', params, headers: userAuth()});},
  async getPartner(params) { return fetchAPI('/admin/partners/' + params.id, { method: 'GET', params, headers: userAuth()});},
  async createPartner(data) { return fetchAPI('/admin/partners', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updatePartner(data) { return fetchAPI('/admin/partners/'+data.id, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  async deletePartner(params) { return fetchAPI('/admin/partners/'+params.id, { method: 'DELETE', headers: userAuth()});},
  // partners categories
  async getPartnersCategories(params) { return fetchAPI('/admin/partners-categories', { method: 'GET', params, headers: userAuth()});},
  async getPartnersCategory(params) { return fetchAPI('/admin/partners-categories/' + params.id, { method: 'GET', params, headers: userAuth()});},
  async createPartnersCategory(data) { return fetchAPI('/admin/partners-categories', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updatePartnersCategory(data) { return fetchAPI('/admin/partners-categories/'+data.id, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  async deletePartnersCategory(params) { return fetchAPI('/admin/partners-categories/'+params.id, { method: 'DELETE', headers: userAuth()});},
  // partners tags
  async getPartnersTags(params) { return fetchAPI('/admin/partners-tags', { method: 'GET', params, headers: userAuth()});},
  async getPartnersTag(params) { return fetchAPI('/admin/partners-tags/' + params.id, { method: 'GET', params, headers: userAuth()});},
  async createPartnersTag(data) { return fetchAPI('/admin/partners-tags', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updatePartnersTag(data) { return fetchAPI('/admin/partners-tags/'+data.id, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  async deletePartnersTag(params) { return fetchAPI('/admin/partners-tags/'+params.id, { method: 'DELETE', headers: userAuth()});},
  // partners states
  async getPartnersState() { return fetchAPI('/admin/partners-locations', { method: 'GET', headers: userAuth()});},
  // plans
  async getMemberships() { return fetchAPI('/admin/plans', { method: 'GET', headers: userAuth()});},

  // users
  async getUsers(params) { return fetchAPI('/admin/users', { method: 'GET', params, headers: userAuth()});},
  async getUser(params) { return fetchAPI('/admin/users/'+params.id, { method: 'GET', params, headers: userAuth()});},
  async updateUsers(data) { return fetchAPI('/admin/users/'+data.id, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  // user analytics user
  async getMetrics(params) { return fetchAPI('/admin/users/'+params.id+'/analytics/metrics', { method: 'GET', headers: userAuth()});},
  async getChart(params) { return fetchAPI('/admin/users/'+params.id+'/analytics/chart', { method: 'GET', params, headers: userAuth()});},
  async getGraph(params) { return fetchAPI('/admin/users/'+params.id+'/analytics/graph', { method: 'GET', params, headers: userAuth()});},
  async getBet(params) { return fetchAPI('/admin/users/'+params.id+'/analytics/bets', { method: 'GET', params, headers: userAuth()});},
  // stream
  async getStreams(params) { return fetchAPI('/admin/stream', { method: 'GET', params, headers: userAuth()});},
  async getStream(params) { return fetchAPI('/admin/stream/'+params.id, { method: 'GET', params, headers: userAuth()});},
  async createStream(data) { return fetchAPI('/admin/stream', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updateStream(data) { return fetchAPI('/admin/stream/'+data.id, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  async deleteStream(params) { return fetchAPI('/admin/stream/'+params.id, { method: 'DELETE', headers: userAuth()});},

  // banners
  async getBanners(params) { return fetchAPI('/admin/banners', { method: 'GET', params, headers: userAuth()});},
  async getBanner(params) { return fetchAPI('/admin/banners/'+params.id, { method: 'GET', headers: userAuth()});},
  async createBanner(data) { return fetchAPI('/admin/banners', { method: 'POST', body: JSON.stringify(data), headers: userAuth()});},
  async updateBanner(data) { return fetchAPI('/admin/banners/'+data.id, { method: 'PATCH', body: JSON.stringify(data), headers: userAuth()});},
  async deleteBanner(params) { return fetchAPI('/admin/banners/'+params.id, { method: 'DELETE', headers: userAuth()});}
};

// userAuth()
